import React from "react"
import Layout from "@components/layout"

// Import SVG
import icon_t_SVG1 from '@images/top_t_icon1.svg';
import icon_post_SVG3 from '@images/post_icon3.svg';

// WP CSS
import "@css/@wordpress/block-library/build-style/style.css"
import "@css/@wordpress/block-library/build-style/theme.css"



// コンポーネント呼び出し
import { GraphqlArray } from "@components/graphql-data-campaign.js"; // 記事情報取得・形成
import { SearchCategList } from "@components/search-categList.js"; // カテゴリ検索機能
import { RelatedContents } from "@components/related-article.js"; // 関連記事取得・形成
import { FixedFoot } from "@components/footer";
import { WordSearch } from "@components/word-search.js";
import { ProfileContents } from "@components/profile.js";
import { SeoContents } from "@components/seo-data.js" // Seo情報設定

const PropCalender = () => {
  const indexUrl = '/';
  const pageTitle = 'マーケット情報'
  const name = '業者情報' // 関連取得の為ダミータグ設定  
  // const searchList = SearchCategList().Main; // カテゴリ検索リスト
  const wordSearchContentPC = WordSearch().PC; // ワード検索PC
  const wordSearchContentMB = WordSearch().MB; // ワード検索MB
  let fixedItem = FixedFoot( 'nomal' ); // 固定フッター
  
  // Seo情報設定
  const pathName = 'contact';
  const seoTag = SeoContents({ Path:pathName });
      
  return (
    <> 
      <Layout>
        {seoTag}


        <div className="bg-[#F6F6F6] md:pb-[45px] pb-2">
        <div className="px-5 max-w-xl mx-auto md:max-w-5xl ">
          <div className="pb-4 pt-0 md:pt-10  pt-0  max-w-[800px] ">
          <h2 className="md:text-5xl text-3xl font-black mb-10 text-left pt-5">{pageTitle}</h2>
          <h3 className="md:text-3xl text-2xl font-black  text-left  text-[#1F55A6]">Economic index</h3>
          </div>
        </div>
        </div>


        <div className="px-5 max-w-xl mx-auto md:max-w-5xl  mt-[70px] mb-[100px] ">
          <p className="mb-12">世界の経済指数の発表スケジュールと予測を掲載しています。</p>
          <div className="pb-5 mb-10 md:pb-0 md:mb-0 w-full">

                <iframe scrolling="no" src="https://www.mql5.com/ja/economic-calendar/widget?mode=2&utm_source=miltonmarkets.com" width="100%" height="500"></iframe>
                </div>


        </div>

        {/* 固定フッター */}
        {fixedItem}   
      </Layout> 
    </>
  )
}
export default PropCalender
