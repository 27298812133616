import React from "react"
import SeoData from "@content/seo/fx.json"
import Seo from "@components/seo"

// Import image
import ogpimage from '@images/ogp_just2.png';

// 固定ページSEO情報生成
export function SeoContents ({ Path:pathName, Title:setTitle }) {

  // archiveページ用設定
  const indexUrl = '';
  const setDescription ="日本発のプロップファーム「" + setTitle +"」 Just tradeの資金で最大4,000万円の取引が可能。あなたの投資損失を請け負い、利益を最大90%還元します。"; 
  
  // ogpUrl設定
  let ogpUrl;
  if ( pathName === 'categories' || pathName === 'tags') { // アーカイブページの処理
    ogpUrl = process.env.ORIGINURL + "/" + indexUrl + pathName + "/" + setTitle + "/";
  } else if ( pathName === 'index' ) { // トップページ
    ogpUrl = process.env.ORIGINURL + "/" + indexUrl ;
  } else { // その他ページ
    ogpUrl = process.env.ORIGINURL + "/" + indexUrl + pathName + "/";
  }
  // fx.jsonに設定した情報を取得
  const title = SeoData[ pathName + "_title" ];
  const description = SeoData[ pathName + "_description" ];
  
  // 共通設定
  const ogpSiteName = 'Just trade 日本発のプロップファーム';
  const ogpImage = process.env.ORIGINURL + ogpimage;
  const ogpImageWidth = '1200';
  const ogpImageHeight = '630';
  const ogpImageType = 'image/png';

  const seoTag =
  <Seo
    title={setTitle ? setTitle : title} // アーカイブページはsetTitleを表示、固定ページはtitleを表示
    description={setTitle ? setDescription : description} // アーカイブページはsetDescriptionを表示、固定ページはdescriptionを表示
    ogpUrl={ogpUrl}
    ogpSiteName={ogpSiteName}
    ogpImage={ogpImage}
    ogpImageWidth={ogpImageWidth}
    ogpImageHeight={ogpImageHeight}
    ogpImageType={ogpImageType}
  />
  return seoTag
}